import {Box, CircularProgress, debounce, InputAdornment, MenuItem, Select} from '@mui/material';
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Info, Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {CustomTooltip} from '../../../../styles/components/CustomTooltip';
import {
  errorBigIcon,
  iconNotifyWarningInfo,
  searchIcon,
  toastSuccess,
} from '../../../../assets/icons/icons';
import {Button, Typography} from '../../../../components/StyledComponents';
import {
  EGithubUserTypes,
  EPaymentRequiredType,
  EPlatformsNames,
  ESubscriptionPlans,
  ETrialStatus,
  SubscriptionMockData
} from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup, IPoppupBase, PlatformProps} from '../../../../models/inner-models';
import Platform from '../../../../models/platforms-data';
import {
  backupGistAll,
  backupRepoAll,
  deleteGithubGist,
  deleteGithubItem,
  fetchGistForGithubUser,
  fetchGithubUsers,
  fetchItemsForGithubUser,
  getAllSubscriptions,
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getGithubAppInstallSelector,
  getGithubCanUserBackup,
  getGithubReposSelector,
  getGithubTotal,
  getGithubUsersSelector,
  getIsTestUser,
  githubSubscription,
  githubTrialHoursLeft,
  githubTrialPlanQuantity,
  githubTrialQuantityLeft,
  githubTrialStatus,
  isGithubRepoLoadingSelector,
  isGithubUsersLoadingSelector, isSubUser,
  loadingUser, roleByApp,
} from '../../../../store/selectors';
import {SearchInput} from '../../../../styles/components/MainInputElements';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import GithubOrgAccInfoDialog from "./GithubOrgAccInfoDialog/GithubOrgAccInfoDialog";
import fetchItemsErrorHandler from "../../../../utils/fetchItemsErrorHandler";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import PlatformItemsList from "../../../../components/PlatformComponents/PlatformItemsList/PlatformItemsList";
import GithubRepoAdd from "./GithubRepoAddDialog/GithubRepoAdd";
import GithubGistAdd from "./GithubRepoAddDialog/GithubGistAdd";
import PaymentRequiredPoppup from "../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup";
import {defaultTrialReposMax, EWebsocketMethods} from "../../../../constants";
import NoSubscriptionBlock from "../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock";
import NoBackupsBlock from "../../../../components/Blocks/NoItemsBlock/NoItemsBlock";
import UserNotConfirmedBlock from "../../../../components/Blocks/UserNotConfirmedBlock/UserNotConfirmedBlock";
import Modal from "../../../../components/Dialogs/Modal/Modal";
import GithubUserAddEdit from "../GithubUserAddEditView/GithubUserAddEdit";
import DeleteItemDialog from "../../../../components/Dialogs/DeleteItemDialog/DeleteItemDialog";
import DatabaseIcoInvert from "../../../../assets/icons/CustomSvg/DatabaseIcoInvert";
import {IDeletePayload, IPlatformUser} from "../../../../models/models";
import {
  BadgesAppWasDeleted,
  BadgesBackup,
  BadgesAddingItems,
  BadgesSubscription,
  BadgesTrial
} from "../../../../components/Badges/Badges";
import GithubAddOrg from "./GithubAddOrg/GithubAddOrg";
import CustomTimerTooltip from "../../../../styles/components/CustomTimerTooltip";
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import ExternalStorage from "../../../UserPages/UserSettings/ExternalStorage/ExternalStorage";
import {useWebSocket} from "../../../../contexts/WebSocketContext";

const GithubPlatformSettings: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const isLoadingRepos = useAppSelector(isGithubRepoLoadingSelector);
  const isLoadingGithubUsers = useAppSelector(isGithubUsersLoadingSelector);
  const trialState = useAppSelector(githubTrialStatus);
  const repos = useAppSelector(getGithubReposSelector);
  const total = useAppSelector(getGithubTotal);
  const canUserBackup = useAppSelector(getGithubCanUserBackup);
  const isTestUser = useAppSelector(getIsTestUser);
  const trialDays = useAppSelector(githubTrialHoursLeft);
  const trialQuantity = useAppSelector(githubTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(githubTrialPlanQuantity);
  const subInfo = useAppSelector(githubSubscription);
  const users = useAppSelector(getGithubUsersSelector);
  const hasUserApp = useAppSelector(getGithubAppInstallSelector);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.github === 'admin' || !isUserSub
  // disable btn sub not admin - isUserSub && roleApp?.github === 'user'
  // const isValidToken = useAppSelector(getGithubTokenValidSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const platformName = EPlatformsNames.GITHUB;
  const platform = Platform[platformName];
  const [searchParams] = useSearchParams();
  const [isAppJustAdded, setIsAppJustAdded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [platformInfo, setPlatformInfo] = useState<PlatformProps>(
    {
      platformName: platformName,
      subPlan: SubscriptionMockData[ESubscriptionPlans.NO_SUB],
      items: repos || [],
      filteredItems: repos || [],
      users: users || [],
    },
  );

  const [notification, setNotification] = useState<IPoppupBase>({isOpen: false,});
  const [notificationSec, setNotificationSec] = useState<IPoppupBase>({isOpen: false,});
  const [notificationPlace, setNotificationPlace] = useState<IPoppupBase>({isOpen: false});
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName,
  });
  const [paymentState, setPaymentState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [buyShowed, setBuyShowed] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showSettings2, setShowSettings2] = useState<boolean>(false);
  const [deleteDate, setDeleteData] = useState<{ id?: string, name?: string, type?: string }>({});
  const [deleteBulkDate, setDeleteBulkData] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<Array<string>>([]);
  const [progressItem, setProgressItem] = useState<string>('');
  const appWasDeleted = !!users && (!hasUserApp || !users.filter(i => i.githubapp).length) && platformInfo?.items?.length !== 0 //&& !users.filter(i => i.isConfirmed).length
  const [progressEnd, setProgressEnd] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [intervalFunc, setIntervalFunc] = useState<any>();
  const [userId, setUserId] = useState<number>(1);
  const [userCanBackup, setUserCanBackup] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [addingItemsData, setAddingItemsData] = useState<{ addedItems: number; totalItems: number }>({ addedItems: 0, totalItems: 0});
  const {onMessage} = useWebSocket();

  useEffect(() => {
    const isExt = searchParams.get('isCloudStorageConnected');
    const errorMessage = searchParams.get('error_message');

    if (!errorMessage && isExt) {
      navigate(window.location.pathname, { replace: true })
      setShowSettings2(true)
    }
  })

  const clearState = (notPage?: boolean) => {
    setDeleteData({})
    setDeleteBulkData([])
    setSelectList([])
    setSelectAll(false)
    if (!notPage) {
      setPage(0)
    }
  }

  useEffect(() => {
    setUserCanBackup(canUserBackup)
  }, [canUserBackup])

  const restartBadge = () => {
    if (intervalFunc) {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
    }
    setProgressEnd(false)
    setProgressItem('')
  }

  const setUserPlace = (userList: IPlatformUser[] | null) => {
    if (!!userList?.length && userList[0].id && (userId === 1 || !localStorage['githubUser'] || Number(localStorage['githubUser']) !== userId || localStorage['githubUser'] === '111')) {
      if (Number(localStorage['githubUser']) === 0) {
        setUserId(0)
      } else if (localStorage['githubUser'] && !!userList?.filter(i => i.id === Number(localStorage['githubUser']))?.length) {
        setUserId(Number(localStorage['githubUser']))
        return localStorage['githubUser'] as number
      } else {
        localStorage['githubUser'] = userList[0].id
        setUserId(userList[0].id)
        return userList[0].id
      }
    }
  }

  useEffect(() => {
    setTimeout(() => setUserPlace(users), 2000)
  }, []);

  const fetchData = async (newPage?: number,  name?:string) => {
    try {
      setIsLoading(true)
      restartBadge()
      let usersList: { result: IPlatformUser[] | null } = {result: null}
      if (!users) {
        usersList = await dispatch(fetchGithubUsers()).unwrap().catch(err => console.log(err));
      }

      Number(localStorage['githubUser']) === 0 ?
        await dispatch(fetchGistForGithubUser({page: newPage || 0, perPage: 10, name})).unwrap()
          .catch((err) => {
            console.log(err)
            setIsLoading(false)
            const error = err as IError;
            fetchItemsErrorHandler(error)
          })
          .finally(() => {
            setIsLoading(false)
          }) :
        await dispatch(fetchItemsForGithubUser({
          page: newPage || 0,
          perPage: 10,
          placeId: users && userId !== 1 ? userId : setUserPlace(!!usersList?.result ? usersList.result : users) as number,
          name
        })).unwrap()
          .catch((err) => {
            console.log(err)
            setIsLoading(false)
            const error = err as IError;
            fetchItemsErrorHandler(error)
          })
          .finally(() => {
            setIsLoading(false)
          })

    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) {
      navigate('/app-connector')
    }
    fetchData();

    onMessage((data) => {
      if(EWebsocketMethods.itemsAddedCountChange === data.method && data.platform === EPlatformsNames.GITHUB){
      setAddingItemsData({addedItems:data.addedItems,totalItems:data.totalItems})
        if(data.addedItems === data.totalItems){
          fetchData()
        }
      }
    });
  }, []);

  useEffect(() => {
    if ((platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.SUBSCRIPTION && !platformInfo.subPlan.isPaid)) {
      setPaymentState(
        platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours ?
          EPaymentRequiredType.TRIAL : platformInfo.subPlan.isPaid ?
            EPaymentRequiredType.NON : EPaymentRequiredType.SUB,
      );
    } else {
      setPaymentState(EPaymentRequiredType.NON)
    }
  }, [platformInfo]);

  useEffect(() => {
    if (isUserSub && !roleApp?.[platformName]) {
      navigate('/', {replace: true})
    }
  }, [isUserSub, roleApp]);

  useEffect(() => {
    if (!isUserSub && !isLoadingRepos && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && !buyShowed) {
      setBuyShowed(true)
      Swal.fire({
        title: !!subInfo ? translate('notifications.subscription.subscribe_buy') : translate('notifications.subscription.subscribe_buy_trial'),
        text: !!subInfo ? translate('notifications.subscription.subscribe_buy_text') : translate('notifications.subscription.subscribe_buy_text_trial'),
        cancelButtonText: translate('notifications.choices.close'),
        confirmButtonText: translate('notifications.choices.buy_subscription_now'),
        showCancelButton: true,
        imageUrl: errorBigIcon,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName)
            navigate('/checkout');
          }
        })
    }
  }, [isLoadingRepos, subInfo]);

  const addRepos = () => {
    if (paymentState !== EPaymentRequiredType.NON) {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    } else {
      setNotification({
        isOpen: true,
      });
    }
  };

  const addGist = () => {
    if (paymentState !== EPaymentRequiredType.NON) {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    } else {
      setNotificationSec({
        isOpen: true,
      });
    }
  };

  const onConfirmDeleteRepo = async (id?, name?, isDelBulk?) => {
    try {
      // setIsLoading(true)
      const payload: IDeletePayload = {}
      let key = ''
      if (!!userId) {
        payload.placeId = userId
        key = 'reposIds'
      } else {
        key = 'gistsIds'
      }
      payload[key] = []
      if (!isDelBulk) {
        payload[key].push({id: id})
      } else {
        deleteBulkDate.forEach(item => {
          payload[key].push({id: item})
        })
      }
      if (!!userId) {
        await dispatch(deleteGithubItem(payload)).unwrap()
        await dispatch(fetchItemsForGithubUser({page: 0, perPage: 10, placeId: userId})).unwrap()
      } else {
        await dispatch(deleteGithubGist(payload)).unwrap()
        await dispatch(fetchGistForGithubUser({page: 0, perPage: 10})).unwrap()
      }
      dispatch(getAllSubscriptions()).unwrap()

      restartBadge()
      clearState()

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: isDelBulk ?
          translate('notifications.github_repos.successfully_deleted_many') :
          !!userId ?
            translate('notifications.github_repos.successfully_deleted') :
            translate('notifications.github_repos.successfully_deleted_gist'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      // setIsLoading(false)

    } catch (err) {
      const error = err as IError;
      // setIsLoading(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const handleDeleteRepo = (id, name, type) => {
    setDeleteData({id, name, type});
  };

  const handleDeleteBulkBoard = async (arr) => {
    setDeleteBulkData(arr);
  };

  const closeNotification = useCallback(() => {
    setNotification({...notification, isOpen: false});
    setNotificationSec({...notification, isOpen: false});
    setNotificationPlace({...notification, isOpen: false});
  }, []);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({...paymentPoppup, isOpen: false});
  }, []);

  const getRemainingItems = useCallback(() => {
    if (!platformInfo.subPlan) {
      return 0;
    }

    if (platformInfo.subPlan.quantity !== undefined && platformInfo.subPlan.quantity >= 0 && platformInfo.items) {
      const payedItemsLeft = platformInfo.subPlan.quantity;
      return payedItemsLeft > 0 ? payedItemsLeft : 0;
    }

    return defaultTrialReposMax;
  }, [platformInfo]);

  const validationBlock = useCallback(() => {
    if ((!!users?.filter(i => i.isConfirmed).length && platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED && !repos?.length)) {
      return <NoSubscriptionBlock platformName={platformName} isOwner={!isUserSub}/>;
    }
    if (!users?.length) {
      return <NoBackupsBlock
        isAdmin={!isUserSub}
        action={() => navigate('/app-connector')}
        platformItem={translate('views.bulk_restore.itemName.github.one')}
        actionText={translate('forms.github_add_edit_repo.configure_account')}
      />;
    }

    if ((!hasUserApp || !users.filter(i => i.githubapp).length) && platformInfo.items?.length === 0) {
      return <UserNotConfirmedBlock noApp={true} isOwner={!isUserSub}/>;
    }

    if (!!users?.length && (!users.filter(i => i.isConfirmed).length && !platformInfo.items?.length) || (!users.filter(i => i.isConfirmed).length && (hasUserApp || !!users.filter(i => i.githubapp).length) && !!platformInfo.items?.length)) {
      return <UserNotConfirmedBlock updUserList={setUserPlace} isOwner={!isUserSub}/>;
    }

    if (!platformInfo.items?.length) {
      return <NoBackupsBlock
        isAdmin={isAdminRole}
        action={!!userId ? addRepos : addGist}
        platformItem={translate('views.bulk_restore.itemName.github.one')}
        actionText={!!userId ? translate('forms.github_add_edit_repo.add_repo_num', {num: ''}) : translate('forms.github_add_edit_repo.add_gist_num', {num: ''})}
      />;
    }

    return null;
  }, [platformInfo, trialState, users, paymentState]);

  const backupAllNow = async () => {
    try {
      setIsLoading(true)
      let backResp: Record<string, string> | null = null
      if (!!userId) {
        backResp = await dispatch(backupRepoAll(userId)).unwrap();
        // dispatch(fetchItemsForGithubUser(userId)).unwrap()
      } else {
        backResp = await dispatch(backupGistAll()).unwrap();
        // dispatch(fetchGistForGithubUser()).unwrap()

      }
      restartBadge()
      if (total === 1) {
        setProgressItem(translate(`notifications.all.actionBadge.one.backing_up_process`, {
          app: 'GitHub',
          item: platformInfo.items?.[0]?.repo?.split('::')[0]
        }))
      } else {
        setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'GitHub'}))
      }

      if (!isTestUser) {
        setUserCanBackup(false)
      }

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: total > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      setIsLoading(false)
    } catch (err) {
      const error = err as IError;
      setIsLoading(false)

      if (error.code === 'err.user.backup.limit') {
        setUserCanBackup(false)
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: iconNotifyWarningInfo,
          timer: 3000,
        });
      } else {
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    }
  }

  useEffect(() => {
    if (!isAppJustAdded && !!searchParams.get('isAppJustAdded') && users && users.findIndex(i => i.id === userId && i.userType === EGithubUserTypes.ORGANIZATION) !== -1) {
      setIsAppJustAdded(true)
    } else if (!!searchParams.get('isAppJustAdded') && users && users.findIndex(i => i.id === userId && i.userType === EGithubUserTypes.USER) !== -1) {
      navigate(`/platform/dashboard/github`, {replace: true});
    }
  }, [users, searchParams, isAppJustAdded])

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);
      setPlatformInfo((prev) => ({
        ...prev,
        subPlan: {...subPlan},
      }));
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingRepos && !isLoadingGithubUsers) {
      setPlatformInfo((prev) => ({
        ...prev,
        items: repos || [],
        users: users || [],
        filteredItems: repos || [],
      }));
      setUserPlace(users)

      const filt = repos?.filter(itemB => !!itemB.actionProcess?.length || itemB.status === 'initialized')
      if (filt && filt.length > 1 && !progressItem.length) {
        const isRest = filt.filter(itemB => itemB.actionProcess === 'restoring_process')
        filt.length === isRest?.length ?
          setProgressItem(translate(`notifications.all.actionBadge.many.restore`, {app: 'GitHub'})) :
          !!isRest?.length ?
            setProgressItem(translate(`notifications.all.actionBadge.multi`)) :
            setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'GitHub'}))
      } else if (filt?.length === 1 && !progressItem.length) {
        setProgressItem(translate(`notifications.all.actionBadge.one.${filt[0].actionProcess || 'backing_up_process'}`, {
          app: 'GitHub',
          item: filt[0].repo?.split('::')[0]
        }))
      } else if (!filt?.length && !!progressItem?.length) {
        if (intervalFunc) {
          clearInterval(intervalFunc)
        }
        setProgressEnd(true)
      }
    }
  }, [repos, users, isLoadingRepos, isLoadingGithubUsers]);

  const debouncedSearch = useCallback(
    debounce((event) => {
      setPage(0)
      fetchData(0, event.toLowerCase())
    }, 1000),
    [],
  );

  const onSearch = useCallback((event) => {
    const val = event.target.value || ''
    setSearchTerm(val);
    debouncedSearch(val)
  }, []);

  const addOrg = () => {
    if (paymentState !== EPaymentRequiredType.NON) {
      setPaymentPoppup((prev) => ({
        ...prev,
        isOpen: true,
      }));
    } else {
      setNotificationPlace({
        isOpen: true
      });
    }
  };

  return (
    <div id="platform-content" className='list-view'>
      {!!progressItem?.length && (
        <BadgesBackup progressItem={progressItem} platformName={platformName} isComplete={progressEnd}
                      onClose={() => restartBadge()}/>
      )}
      {(
          addingItemsData.totalItems > 0 && addingItemsData.addedItems > 0 && <BadgesAddingItems platformName={platformName} addingItemsData={addingItemsData}
                           onClose={() => setAddingItemsData({ addedItems : 0,totalItems : 0 })}/>
      )}
      {trialState === 'active' && trialDays && (
        <BadgesTrial trialDays={trialDays} platformName={platformName}/>
      )}
      {!isLoadingRepos && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && (
        <BadgesTrial platformName={platformName}
                     color={!!repos?.filter(i => i.lastBackup !== '-').length ? 'error' : 'error_2'} isSub={!!subInfo}/>
      )}
      {/*{!isLoadingRepos && ((trialState === 'active' && trialDays && !trialQuantity) || (subInfo && subInfo.status === 'active' && !subInfo.quantity)) && (*/}
      {/*  <BadgesTrial platformName={platformName} color='warning' isSub={subInfo ? subInfo.status === 'active' : false}/>*/}
      {/*)}*/}
      {appWasDeleted && (
        <BadgesAppWasDeleted platformName={platformName}/>
      )}
      {/*{!isValidToken && platformInfo.users.findIndex(i => i.id === userId && i.userType === EGithubUserTypes.ORGANIZATION) !== -1 && (*/}
      {/*  <BadgesTokenWasDeleted platformName={platformName} openSetToken={()=>setOpenToken(true)} openHowModal={()=>setInfoToken(true)}/>*/}
      {/*)}*/}
      {subInfo && subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo?.finishDateInMillisecond- Date.now())/(24*60*60*1000))<1 &&<BadgesSubscription platformName={platformName}/>}

      <div className="header-block">
        <Typography variant="h3" gutterBottom display="inline">
          {translate('forms.github_add_edit_repo.your_items', {platform: platform?.title, itemName: ''})}
        </Typography>

        <div className="search-block">
          <div className="action-button">
            {/*{(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && (*/}
            {/*  <Button variant="outlined" color="primary"*/}
            {/*          onClick={() => setShowSettings(true)}>*/}
            {/*    {translate('forms.github_add_edit_repo.open_setting')}*/}
            {/*  </Button>*/}
            {/*)}*/}
            {(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && !isLoadingGithubUsers && hasUserApp && (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
              >
                <Button variant="outlined" color="primary"
                        disabled={platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial' || paymentState !== EPaymentRequiredType.NON}
                        onClick={() => setShowSettings2(true)}>
                  External storage
                </Button>
              </CustomTooltip>
            )}
            {!!repos?.length && ((userCanBackup || isTestUser) ? (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
                <Button
                  ml={2} variant="outlined"
                  color="primary" onClick={()=>(platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? backupAllNow() : updateSubscriptionPlanModal({translate,platformName,navigate}) }
                  disabled={(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                    (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) || appWasDeleted ||
                    paymentState !== EPaymentRequiredType.NON || !isAdminRole}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTooltip>
            ) : (
              <CustomTimerTooltip setUserCanBackup={() => setUserCanBackup(true)}>
                <Button
                  ml={2} variant="outlined"
                  color="primary" onClick={backupAllNow}
                  disabled={true}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTimerTooltip>
            ))}
            {!!userId && !!platformInfo.users?.length && !!users?.filter(i => i.isConfirmed).length && (
              <Button
                ml={2} variant="contained" color="primary" onClick={addRepos}
                disabled={!isAdminRole || isUserSub && subInfo?.status !== 'active' && trialState !== 'active'}
              >
                <Plus className="feather-icon-in-button"/>
                {translate('forms.github_add_edit_repo.add_repo_num', {num: ''})}
              </Button>
            )}
            {!userId && !!platformInfo.users?.length && !!users?.filter(i => i.isConfirmed).length && (
              <Button ml={2} variant="contained" color="primary" onClick={addGist}
                      disabled={!isAdminRole || isUserSub && subInfo?.status !== 'active' && trialState !== 'active'}
              >
                <Plus className="feather-icon-in-button"/>
                {translate('forms.github_add_edit_repo.add_gist_num', {num: ''})}
              </Button>
            )}
            {/*{platformInfo && !!platformInfo.users?.length && platformInfo.users.findIndex(i => i.id === userId && i.userType === EGithubUserTypes.ORGANIZATION) !== -1 && (*/}
            {/*  <>*/}
            {/*    <Button ml={2} mr={2} variant="contained" color="primary" onClick={()=>setOpenToken(true)}>*/}
            {/*      Set personal token*/}
            {/*    </Button>*/}
            {/*    <span onClick={() => {*/}
            {/*      setInfoToken(true)*/}
            {/*      }}*/}
            {/*          title='How to create a personal access token'*/}
            {/*      >*/}
            {/*      <Info className="sliders-icon"/>*/}
            {/*    </span>*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
      <div className="header-block header-block-sub">
        <div className="user-name">
          {!!users?.length && users.some(user => user.isConfirmed) && (
            <Select
              value={userId}
              className='github-org-select'
              MenuProps={{id: 'sub-github-select'}}
            >
              {users?.map(user => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  onClick={() => {
                    setIsLoading(true)
                    restartBadge()
                    clearState()
                    dispatch(fetchItemsForGithubUser({
                      page: page,
                      perPage: 10,
                      placeId: user.id as number
                    })).unwrap().finally(() => setIsLoading(false))
                    localStorage['githubUser'] = user.id
                    setUserId(user.id as number)
                  }}
                >
                  @{user.username}
                  {user.userType === EGithubUserTypes.ORGANIZATION ? ' (Organization)' : ': Repositories'}
                </MenuItem>
              ))}
              {users?.find(i => i.userType === EGithubUserTypes.USER)?.username &&
                  <MenuItem
                      key='0-gist'
                      value={'0'}
                      onClick={() => {
                        setIsLoading(true)
                        restartBadge()
                        clearState()
                        setSearchTerm('')
                        dispatch(fetchGistForGithubUser({
                          page: page,
                          perPage: 10
                        })).unwrap().finally(() => setIsLoading(false))
                        localStorage['githubUser'] = 0
                        setUserId(0)
                      }}
                  >
                      @{users?.find(i => i.userType === EGithubUserTypes.USER)?.username}: Gists
                  </MenuItem>
              }
            </Select>
          )}
          {!isUserSub && platformInfo && !!platformInfo.users?.length &&
            platformInfo.users.findIndex(i => i.id === userId && i.userType === EGithubUserTypes.ORGANIZATION) !== -1 && (
              <span onClick={() => {
                setIsAppJustAdded(true)
              }}
                    title={translate('notifications.github_org_info.tooltip')}
              >
              <Info className="sliders-icon"/>
            </span>
            )}
          {!!users && !!users.filter(i => i.isConfirmed).length && (hasUserApp || !!users.filter(i => i.githubapp).length) && (
            <CustomTooltip
              title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
            >
              <Button ml={2} variant="contained" color="primary" disabled={!isAdminRole} onClick={()=>(platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? addOrg() : updateSubscriptionPlanModal({translate,platformName,navigate}) }>
                <Plus className="feather-icon-in-button"/>
                {translate('forms.github_add_edit_repo.add_place')}
              </Button>
            </CustomTooltip>
          )}
        </div>
        <div className="search-block">
          {(!!repos?.length || !!searchTerm ) && (
            <SearchInput
              className="search-field"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(event)=> {
                onSearch(event)
              }}
              endAdornment={
                <InputAdornment position="end" className="search-icon">
                  <img src={searchIcon} loading="lazy"/>
                </InputAdornment>
              }
            />
          )}
        </div>
      </div>

      <Box sx={{width: '100%'}}>
        {isLoadingGithubUsers || isLoadingRepos || userLoading || isLoading ?
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div> :
          <div className="data-block">
            {validationBlock()}
            {!validationBlock() &&
                <>
                    <div className="repo-wrapper">
                        <div className="repos-list">
                            <PlatformItemsList
                                items={platformInfo.filteredItems || []}
                                updItems={() => !!userId ? dispatch(fetchItemsForGithubUser({
                                  page: page,
                                  perPage: 10,
                                  placeId: userId
                                })).unwrap() : dispatch(fetchGistForGithubUser({page: page, perPage: 10})).unwrap()}
                                platformName={platformName}
                                subPlatform={!!userId ? '' : 'gist'}
                                onDelete={handleDeleteRepo}
                                onBulkDelete={handleDeleteBulkBoard}
                              // onBackup={(id, name, type) => {
                              //   return (
                              //     type ?
                              //       dispatch(backupGithubItem(name)).unwrap() :
                              //       dispatch(backupGithubGist(id)).unwrap()
                              //   )
                              // }}
                                setSelectAll={(isSelect) => {
                                  if (!isSelect) {
                                    setSelectList([])
                                    setSelectAll(isSelect)
                                  } else {
                                    const listToSelect = platformInfo.filteredItems?.filter(item => !item.actionProcess && item.status !== "initialized").map(item => item.id) || []
                                    setSelectList(listToSelect)
                                    if (listToSelect.length === platformInfo.filteredItems?.length) {
                                      setSelectAll(isSelect)
                                    }
                                  }
                                }}
                                selectAll={selectAll}
                                setSelectList={(name) => (
                                  setSelectList(prevState => {
                                    const indexToRemove = prevState.indexOf(name);
                                    if (indexToRemove !== -1) {
                                      prevState.splice(indexToRemove, 1);
                                    } else {
                                      prevState.push(name)
                                    }
                                    if (!!prevState?.length && !!platformInfo.filteredItems?.length &&
                                      ((prevState.length === platformInfo.filteredItems.length && !selectAll) ||
                                        (prevState.length !== platformInfo.filteredItems.length && selectAll))) {
                                      setSelectAll(prevState.length === total)
                                    }
                                    if (!prevState.length) {
                                      setSelectAll(false)
                                    }
                                    return [...prevState]
                                  })
                                )}
                                selectList={selectList}
                                subPlan={platformInfo.subPlan}
                                trialState={trialState}
                                appWasDeleted={appWasDeleted}
                                total={total}
                                page={page}
                                setPage={(p) => {
                                  setPage(p)
                                  clearState(true)
                                  fetchData(p, searchTerm)
                                }}
                                isLoading={isLoading}
                                isAdmin={isAdminRole}
                            />
                        </div>
                    </div>
                </>
            }
            <GithubRepoAdd
              availableItemsLeft={getRemainingItems()}
              updItems={() => {
                dispatch(fetchItemsForGithubUser({page: page, perPage: 10, placeId: userId})).unwrap()
                restartBadge()
              }}
              isOpen={notification.isOpen}
              user={users ? users.filter(i => i.id === userId)[0] : {}}
              closeNotification={closeNotification}
              platformName={platformName}
              paymentType={paymentState}
              subPlan={platformInfo.subPlan}
              isAppInstall={hasUserApp || !!users?.filter(i => i.githubapp).length}
            />
            <GithubAddOrg
              isOpen={notificationPlace.isOpen}
              user={users ? users.filter(i => i.id === userId)[0] : {}}
              closeNotification={closeNotification}
              openOrgInfo={() => {
                setIsAppJustAdded(true)
              }}
            />
            <GithubGistAdd
              availableItemsLeft={getRemainingItems()}
              updItems={() => {
                dispatch(fetchGistForGithubUser({page: page, perPage: 10})).unwrap()
                restartBadge()
              }}
              isOpen={notificationSec.isOpen}
              user={users ? users.filter(i => i.userType === EGithubUserTypes.USER)[0] : {}}
              closeNotification={closeNotification}
              platformName={platformName}
              paymentType={paymentState}
              subPlan={platformInfo.subPlan}
              isAppInstall={hasUserApp || !!users?.filter(i => i.githubapp).length}
            />
            <PaymentRequiredPoppup
              isOpen={paymentPoppup.isOpen}
              closeNotification={closeTrialPoppup}
              type={paymentState}
              platformName={paymentPoppup.platformName}
            />
            <Modal
              isOpen={showSettings}
              closeNotification={() => setShowSettings(false)}
              title='Edit settings'
              child={<GithubUserAddEdit
                user={users ? users.filter(i => i.id === userId)[0] : {}}
                subPlan={platformInfo.subPlan}
                page={page}
                onClose={() => setShowSettings(false)}
              />}
            />
            <Modal
              isOpen={showSettings2}
              className='ext-storage-modal'
              closeNotification={() => setShowSettings2(false)}
              child={<ExternalStorage
                platformModal='github'
                closeModal={() => setShowSettings2(false)}
              />}
            />
            <DeleteItemDialog
              isOpen={!!deleteDate?.name?.length || !!deleteBulkDate.length}
              closeNotification={() => {
                clearState(true)
              }}
              platformName={platformName}
              isBulk={!!deleteBulkDate.length}
              bulkData={deleteBulkDate}
              singleData={deleteDate}
              paymentState={paymentState}
              items={platformInfo.filteredItems || []}
              name={deleteDate?.name || ''}
              updItems={() => !!userId ? dispatch(fetchItemsForGithubUser({
                page: page,
                perPage: 10,
                placeId: userId
              })).unwrap() : dispatch(fetchGistForGithubUser({page: page, perPage: 10})).unwrap()}
              onConfirm={() => {
                deleteBulkDate.length > 1 ?
                  onConfirmDeleteRepo(deleteDate?.id, deleteDate?.name, true) :
                  onConfirmDeleteRepo(deleteBulkDate[0])
              }}
            />
          </div>
        }
      </Box>
      <GithubOrgAccInfoDialog isOpen={isAppJustAdded} closeNotification={() => setIsAppJustAdded(false)}/>
    </div>
  );
};

export default withRedirectAfterLogout()(GithubPlatformSettings);
