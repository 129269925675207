import {Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  CheckboxInputChecked,
  CheckboxInputEmpty,
  closeModalIcon,
  errorBigIcon,
  iconNotifyWarningInfo,
  searchIcon,
  toastSuccess,
  warningBigIcon,
} from '../../../../../assets/icons/icons';
import PaymentRequiredPoppup from '../../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import {Button} from '../../../../../components/StyledComponents';
import {EApiStatuses} from '../../../../../constants';
import {EPaymentRequiredType, EPlatformsNames} from '../../../../../models/consts';
import {IError, IGithubAddProps, IPaymentRequiredPoppup} from '../../../../../models/inner-models';
import {ISetterUserWithItems} from '../../../../../models/models';
import {
  addGithubGists,
  getAllSubscriptions, getAvailableGistsForGithubUser
} from '../../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import {SearchInput} from '../../../../../styles/components/MainInputElements';
import {decoratePopUpMessage} from '../../../../../utils/popUpTextDecorator';
import './style.scss';
import {BadgesTrial} from "../../../../../components/Badges/Badges";
import {isSubUser} from "../../../../../store/selectors";
let abortController: AbortController | null

const GithubGistAdd: FunctionComponent<IGithubAddProps> = ({
  isOpen,
  closeNotification,
  user,
  platformName,
  paymentType,
  availableItemsLeft,
  subPlan,
  updItems,
  isAppInstall
}: IGithubAddProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t: translate} = useTranslation();
  const {handleSubmit, setValue, trigger, reset} = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });
  const isUserSub = useAppSelector(isSubUser);

  const [availableRepos, setAvailableRepos] = useState<Array<Record<string, string>>>([]);
  const [storeItems, setStoreItems] = useState<Array<Record<string, string>>>([]);
  const [checkedRepos, setCheckedRepos] = useState<Array<string>>([]);
  const [filteredRepos, setFilteredRepos] = useState<Array<Record<string, string>>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableReposLoading, setAvailableReposLoading] = useState<boolean>(true);
  const [addingReposProcessing, setAddingReposProcessing] = useState<boolean>(false);
  // const [autoAdd, setAutoAdd] = useState<boolean>(false);

  const [paymentPopup, setPaymentPopup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName || EPlatformsNames.GITHUB,
    type: paymentType || EPaymentRequiredType.NON,
  });
  const [scroll, setScroll] = useState<boolean>(false);
  const step = (availableItemsLeft >= 20 && availableItemsLeft <= 100) ? availableItemsLeft : (availableItemsLeft > 100) ? 100 : 20;

  async function getAvailableGists() {
    try {
      const page = !storeItems.length ? 0 : Math.trunc(storeItems.length / step)
      abortController = new AbortController();
      const { signal } = abortController;
      const repos = await dispatch(getAvailableGistsForGithubUser({page: page, perPage: step, signal})).unwrap();

      if(signal.aborted){
        return
      }

      if (!repos || !repos.length) {
        setAvailableRepos([]);
        // setStoreRepos([]);
        // setFilteredRepos([]);
        setAvailableReposLoading(false);
        setScroll(false);
        return;
      }

      setAvailableRepos(repos);
      setStoreItems(prevState => prevState.concat(repos));
      setFilteredRepos(prevState => prevState.concat(repos));

      setAvailableReposLoading(false);
      setScroll(false);
    } catch (err) {
      console.log(err);
      setAvailableReposLoading(false);
      setScroll(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && availableRepos.length === step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableGists()
      }
    }
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const searchStr = searchTerm.trim().toLowerCase();
    if (searchStr === '') {
      setFilteredRepos(storeItems || []);
    } else {
      storeItems && setFilteredRepos(storeItems.filter((repo) => repo?.name?.toLowerCase().includes(searchStr)));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!isAppInstall) {
      return;
    }

    if (isAppInstall) {
      setAvailableReposLoading(true);
      setSearch('');
    }

    if (isOpen) {
      dispatch(getAllSubscriptions()).unwrap();
      getAvailableGists();
    } else if (!isOpen) {
      setAvailableRepos([]);
      setStoreItems([]);
      setFilteredRepos([]);
    }
  }, [isOpen]);

  const openPayModal = () => {
    Swal.fire({
      title: translate('notifications.subscription.buy_plan_title'),
      text: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.subscription.buy_plan') :
        translate('notifications.subscription.upgrade_plan'),
      imageUrl: iconNotifyWarningInfo,
      cancelButtonText: translate('notifications.choices.nevermind'),
      confirmButtonText: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.choices.buy_subscription') :
        translate('notifications.choices.upgradeSub'),
      showCancelButton: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        }
      })
  }

  const handleChange = (repoName) => {
    if (checkedRepos.includes(repoName)) {
      setCheckedRepos(checkedRepos.filter((ell) => ell !== repoName));
    } else if ((availableItemsLeft - checkedRepos.length) > -1 && availableItemsLeft > 0) {
      setCheckedRepos([...checkedRepos, repoName]);
    } else if (!isUserSub && ((availableItemsLeft - checkedRepos.length) <= -1 || availableItemsLeft === 0)) {
      openPayModal()
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (availableItemsLeft === 0) {
      Swal.fire({
        title: translate('notifications.subscription.buy_plan_title'),
        text: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.subscription.buy_plan') :
          translate('notifications.subscription.upgrade_plan'),
        imageUrl: iconNotifyWarningInfo,
        cancelButtonText: translate('notifications.choices.nevermind'),
        confirmButtonText: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.choices.buy_subscription') :
          translate('notifications.choices.upgradeSub'),
        showCancelButton: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName || '')
            navigate('/checkout');
          }
        })
    } else {
      setCheckedRepos(checked ? filteredRepos.slice(0, availableItemsLeft).map(item => item.id) : []);
    }
  };

  useEffect(() => {
    setValue('items', checkedRepos);
    trigger('items');
  }, [checkedRepos]);

  const onSubmit: SubmitHandler<ISetterUserWithItems> = async (data) => {
    const adaptedData = {
      gists: data.items,
    };
    if ((availableItemsLeft - checkedRepos.length) <= -1) {
      if (!isUserSub) {
        openPayModal()
      }
      return
    }
    setAddingReposProcessing(true);
    try {
      await dispatch(addGithubGists(adaptedData)).unwrap();
      await updItems();
      setAddingReposProcessing(false);
      handleClose();

      Swal.fire({
        title: translate('notifications.titles.success'),
        text: checkedRepos.length > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError

      handleClose();
      setAddingReposProcessing(false);
      console.log(err);

      if (error.status === EApiStatuses.WARNING) {
        const userChoice = await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: warningBigIcon,
          confirmButtonText: translate('subscriptions.edit_sub'),
        });

        if (userChoice.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        } else {
          await updItems();
        }

        return;
      }

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const openTrialModal = useCallback(() => {
    setPaymentPopup((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [paymentType]);

  const handleClose = useCallback(() => {
    setCheckedRepos([]);
    reset();
    closeNotification && closeNotification();
    abortController && abortController.abort();
    abortController = null
  }, []);

  const closeTrialPopup = useCallback(() => {
    setPaymentPopup({...paymentPopup, isOpen: false});
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="github-repo-edit-title"
        aria-describedby="github-repo-edit-description"
        classes={{
          paper: 'add-edit-github-modal',
        }}
      >
        <DialogTitle id="github-repo-edit-title">
          {translate('forms.github_add_edit_repo.add_new_gist')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          {(subPlan && paymentType === EPaymentRequiredType.NON && (availableItemsLeft - checkedRepos.length < 0 || availableItemsLeft === 0)) && (
            <BadgesTrial platformName={'github'} color='warning' isSub={subPlan.name !== "Trial"} isModal={true}/>
          )}
          <div id="github-repo-edit-description">
              <div className="username-block">
                <div className='github-add-select-all'>
                  <div className='title-line'>
                    Gists
                  </div>
                  <div className='desc-line'>
                    {user?.username &&
                      <>
                        {`A list of all the gists for user “${user?.username}”.`}<br/>
                      </>
                    }
                    {availableItemsLeft - checkedRepos.length > 0
                      ? translate('forms.github_add_edit_repo.repos_left', {left: availableItemsLeft - checkedRepos.length})
                      : translate('forms.github_add_edit_repo.no_backups_left')
                    }
                  </div>
                </div>
                {!availableReposLoading && !!storeItems.length && (
                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder="Search gists..."
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                        <InputAdornment position="end" className="search-icon">
                          <img src={searchIcon} loading="lazy"/>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
              </div>

              {availableReposLoading && isAppInstall &&
                <div className="spinner-wrapper">
                  <CircularProgress/>
                </div>
              }
              {!availableReposLoading &&
                <div className="scrollable-div" onScroll={handleScroll}>
                  {!!filteredRepos.length && (
                    <div className="form-row head-row">
                      <Checkbox
                        className={'checkbox'}
                        checked={storeItems.length === checkedRepos.length || (!!checkedRepos.length && checkedRepos.length === availableItemsLeft)}
                        // disabled={(availableItemsLeft - checkedRepos.length) <= 0}
                        name="repoName"
                        onChange={(e) => {
                          handleSelectAllChange(e.target.checked);
                        }}
                        icon={<CheckboxInputEmpty/>}
                        checkedIcon={<CheckboxInputChecked/>}
                      />
                      Name
                    </div>
                  )}
                  {!!filteredRepos.length && !availableReposLoading ?
                    filteredRepos.map(
                      (repoName, index) =>
                        <div className={(availableItemsLeft - checkedRepos.length) <= 0 && !checkedRepos.includes(repoName.id) ? 'form-row check-inactive' : 'form-row'} key={`${repoName}-${index}`}>
                          <label key={repoName.name} className="input-label-checkbox">
                            <Checkbox
                              className={'checkbox'}
                              value={repoName.id}
                              checked={checkedRepos.includes(repoName.id)}
                              name="repoName"
                              onChange={() => {
                                handleChange(repoName.id);
                              }}
                              icon={<CheckboxInputEmpty/>}
                              checkedIcon={<CheckboxInputChecked/>}
                            />
                            {repoName.name.split('::')[0]} {repoName.description ? `- ${repoName.description}` : ''}
                          </label>
                        </div>,
                    ) :
                    <div className="form-row with-overflow">
                      {user && isAppInstall && !searchTerm?.length ?
                        (subPlan?.planQuantity === subPlan?.quantity ?
                          translate('notifications.trello_boards.nothing_to_add_empty') :
                          translate('notifications.trello_boards.nothing_to_add')) :
                        !!searchTerm?.length && isAppInstall ?
                          translate('notifications.github_repos.no_search_gist') :
                          (<>
                            {translate('notifications.github_repos.github_app_required')}
                            {translate('forms.github_add_edit_user.install_github_app')}
                            <b>
                              <a className="link" href={process.env.REACT_APP_GITHUB_APP_INSTALL_URL} target='_blank'
                                 rel='noreferrer'>
                                {translate('forms.github_add_edit_user.here')}
                              </a>
                            </b>
                          </>)
                      }
                    </div>
                  }
                  {scroll && (
                    <div className="spinner-wrapper">
                      <CircularProgress />
                    </div>
                  )}
                </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>
            {!!storeItems.length && (
              <Button
                onClick={
                  paymentType === EPaymentRequiredType.NON
                    ? handleSubmit(onSubmit)
                    : openTrialModal
                }
                variant="contained"
                color="primary"
                disabled={!checkedRepos.length || addingReposProcessing || ((availableItemsLeft - checkedRepos.length) < 0 && isUserSub)}
              >
                {addingReposProcessing &&
                  <div className="small-spinner-wrapper">
                    <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                  </div>
                }
                {addingReposProcessing
                  ? translate('notifications.choices.processing')
                  : checkedRepos.length > 1 ?
                    translate('forms.github_add_edit_repo.add_gist_num', {num: checkedRepos.length}) :
                    translate('forms.github_add_edit_repo.add_gist')}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <PaymentRequiredPoppup
        isOpen={paymentPopup.isOpen}
        closeNotification={closeTrialPopup}
        type={paymentPopup.type}
        platformName={paymentPopup.platformName}
      />
    </>
  );
};

export default memo(GithubGistAdd);
