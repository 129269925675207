import {Checkbox, CircularProgress, debounce, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  closeModalIcon,
  errorBigIcon,
  searchIcon,
  warningBigIcon, CheckboxInputEmpty, CheckboxInputChecked, iconNotifyWarningInfo, toastSuccess
} from '../../../../../assets/icons/icons';
import PaymentRequiredPoppup from '../../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import { Button } from '../../../../../components/StyledComponents';
import { EApiStatuses } from '../../../../../constants';
import { EPaymentRequiredType, EPlatformsNames } from '../../../../../models/consts';
import { IError, IGitlabAddProps, IPaymentRequiredPoppup } from '../../../../../models/inner-models';
import {ISetterUserWithItems} from '../../../../../models/models';
import {
  addGitlabItems, addGitlabSnippets,
  getAllSubscriptions,
  getAvailableItemsForGitlabUser, getAvailableSnippetsForGitlabUser
} from '../../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import { SearchInput } from '../../../../../styles/components/MainInputElements';
import { decoratePopUpMessage } from '../../../../../utils/popUpTextDecorator';
import { IGitlabProject } from './constants';
import './style.scss';
import {BadgesTrial} from "../../../../../components/Badges/Badges";
import {isSubUser} from "../../../../../store/selectors";
let abortController: AbortController | null

const GitlabProjectAdd: FunctionComponent<IGitlabAddProps> = ({
  isOpen,
  closeNotification,
  user,
  platformName,
  paymentType,
  availableItemsLeft,
  subPlan,
  isSnip,
  updItems
}: IGitlabAddProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { handleSubmit, setValue, trigger, reset } = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });
  const isUserSub = useAppSelector(isSubUser);

  const [availableProjects, setAvailableProjects] = useState<Array<IGitlabProject>>([]);
  const [storeItems, setStoreItems] = useState<Array<IGitlabProject>>([]);
  const [checkedProjects, setCheckedProjects] = useState<Array<string>>([]);
  const [filteredProjects, setFilteredProjects] = useState<Array<IGitlabProject>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableProjectsLoading, setAvailableProjectsLoading] = useState<boolean>(true);
  const [addingProjectsProcessing, setAddingProjectsProcessing] = useState<boolean>(false);
  const [paymentPopup, setPaymentPopup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName || EPlatformsNames.GITLAB,
    type: paymentType || EPaymentRequiredType.NON,
  });
  const [scroll, setScroll] = useState<boolean>(false);
  const [netSearch, setNetSearch] = useState<boolean>(false);
  const step = (availableItemsLeft >= 20 && availableItemsLeft <= 100) ? availableItemsLeft : (availableItemsLeft > 100) ? 100 : 20;

  async function getAvailableProjects(name?:string) {
    try {
      abortController = new AbortController();
      const { signal } = abortController;
      const page = !storeItems.length ? 0 : Math.trunc(storeItems.length / step)
      const projects = await dispatch(isSnip ? getAvailableSnippetsForGitlabUser({page: page, perPage: step,name,signal}) : getAvailableItemsForGitlabUser({page: page, perPage: step,name,signal})).unwrap();

      if(signal.aborted){
        return
      }

      if (!projects || !projects.length) {
        setAvailableProjects([]);
        // setStoreItems([])
        // setFilteredProjects([]);
        setAvailableProjectsLoading(false);
        setScroll(false);
        setNetSearch(false);
        return;
      }

      setAvailableProjects(projects);
      setStoreItems(prevState => prevState.concat(projects));
      setFilteredProjects(prevState => prevState.concat(projects));
      setAvailableProjectsLoading(false);
      setScroll(false);
      setNetSearch(false);
    } catch (err) {
      console.log(err);
      setAvailableProjectsLoading(false);
      setScroll(false);
      setNetSearch(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && availableProjects.length === step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableProjects()
      }
    }
  }
  const debouncedSearch = useCallback(
    debounce((event) => {
      setAvailableProjectsLoading(true)
      setAvailableProjects([]);
      setStoreItems([]);
      setFilteredProjects([]);
      getAvailableProjects(event)
    }, 1000),
    [],
  );
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value || ''
    setSearch(val);
    setNetSearch(true)
    debouncedSearch(val)
  };

  useEffect(() => {
    if (!user || !user.gitlabapp) {
      return;
    }

    if (user?.gitlabapp) {
      setAvailableProjectsLoading(true);
      setSearch('');
    }

    if (isOpen) {
      dispatch(getAllSubscriptions()).unwrap();
      getAvailableProjects();
    } else if (!isOpen) {
      setAvailableProjects([]);
      setStoreItems([]);
      setFilteredProjects([]);
    }
  }, [isOpen]);

  const openPayModal = () => {
    Swal.fire({
      title: translate('notifications.subscription.buy_plan_title'),
      text: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.subscription.buy_plan') :
        translate('notifications.subscription.upgrade_plan'),
      imageUrl: iconNotifyWarningInfo,
      cancelButtonText: translate('notifications.choices.nevermind'),
      confirmButtonText: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.choices.buy_subscription') :
        translate('notifications.choices.upgradeSub'),
      showCancelButton: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        }
      })
  }

  const handleChange = (projectId) => {
    if (checkedProjects.includes(projectId)) {
      setCheckedProjects(checkedProjects.filter((ell) => ell !== projectId));
    } else if ((availableItemsLeft - checkedProjects.length) > -1 && availableItemsLeft > 0) {
      setCheckedProjects([...checkedProjects, projectId]);
    } else if (!isUserSub && ((availableItemsLeft - checkedProjects.length) <= -1 || availableItemsLeft === 0)){
      openPayModal()
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (availableItemsLeft === 0){
      Swal.fire({
        title: translate('notifications.subscription.buy_plan_title'),
        text: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.subscription.buy_plan') :
          translate('notifications.subscription.upgrade_plan'),
        imageUrl: iconNotifyWarningInfo,
        cancelButtonText: translate('notifications.choices.nevermind'),
        confirmButtonText: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.choices.buy_subscription') :
          translate('notifications.choices.upgradeSub'),
        showCancelButton: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName || '')
            navigate('/checkout');
          }
        })
    } else {
      setCheckedProjects((prevState) => {
        if (checked) {
          const newArr = filteredProjects?.map(i => i.id).filter(id => !prevState.includes(id))?.slice(0, availableItemsLeft - prevState.length) || []
          return [...prevState, ...newArr]
        } else {
          return []
        }
      })
    }
  };

  useEffect(() => {
    setValue('items', checkedProjects);
    trigger('items');
  }, [checkedProjects]);

  const onSubmit: SubmitHandler<ISetterUserWithItems> = async (data) => {
    const adaptedData = {
      user: user?.username,
      projects: data.items.map(repo => storeItems.find((project) => project.id === repo) as IGitlabProject),
    };
    if ((availableItemsLeft - checkedProjects.length) <= -1){
      if (!isUserSub) {
        openPayModal()
      }
      return
    }
    setAddingProjectsProcessing(true);
    try {
      // await dispatch(addGitlabItems(adaptedData)).unwrap();
      isSnip ?
        await dispatch(addGitlabSnippets({snippetsIds: data.items})).unwrap() :
        await dispatch(addGitlabItems(adaptedData)).unwrap();

      await updItems();

      if ((availableItemsLeft - checkedProjects.length) === 0) {
        dispatch(getAllSubscriptions()).unwrap()
      }
      setAddingProjectsProcessing(false);
      handleClose();

      Swal.fire({
        title: translate('notifications.titles.success'),
        text: checkedProjects.length > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError

      handleClose();
      setAddingProjectsProcessing(false);
      console.log(err);

      if (error.status === EApiStatuses.WARNING) {
        const userChoice = await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: warningBigIcon,
          confirmButtonText: translate('subscriptions.edit_sub'),
        });

        if (userChoice.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        } else {
          await updItems();
        }

        return;
      }

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const openTrialModal = useCallback(() => {
    setPaymentPopup((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [paymentType]);

  const handleClose = useCallback(() => {
    setCheckedProjects([]);
    reset();
    closeNotification && closeNotification();
    abortController && abortController.abort();
    abortController = null
  }, []);

  const closeTrialPopup = useCallback(() => {
    setPaymentPopup({ ...paymentPopup, isOpen: false });
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="github-repo-edit-title"
        aria-describedby="github-repo-edit-description"
        classes={{
          paper: 'add-edit-github-modal',
        }}
      >
        <DialogTitle id="github-repo-edit-title">
          {translate(`forms.gitlab_add_edit_project.${isSnip ? 'add_new_snip' : 'add_new_project'}`)}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        {/*<div className="repos-left-block">*/}
        {/*  {availableItemsLeft - checkedProjects.length > 0*/}
        {/*    ? translate('forms.gitlab_add_edit_project.repos_left', { left: availableItemsLeft - checkedProjects.length })*/}
        {/*    : translate('forms.gitlab_add_edit_project.no_backups_left')*/}
        {/*  }*/}
        {/*</div>*/}
        <DialogContent>
          {(subPlan && paymentType === EPaymentRequiredType.NON && (availableItemsLeft - checkedProjects.length < 0 || availableItemsLeft === 0)) && (
            <BadgesTrial platformName={'gitlab'} color='warning' isSub={subPlan.name !== "Trial"} isModal={true}/>
          )}
          <div id="github-repo-edit-description">
              <div className="username-block">
                <div className='github-add-select-all'>
                  <div className='title-line'>
                    {isSnip ? 'Snippets' : translate('views.bulk_restore.itemName.gitlab.many')}
                  </div>
                  <div className='desc-line'>
                    {user?.username &&
                      <>
                        {`A list of all the ${isSnip ? 'snippets' : 'projects'} for user “${user?.username}”.`}<br/>
                      </>
                    }
                    {availableItemsLeft - checkedProjects.length > 0
                      ? translate('forms.gitlab_add_edit_project.repos_left', { left: availableItemsLeft - checkedProjects.length })
                      : translate('forms.gitlab_add_edit_project.no_backups_left')
                    }
                  </div>
                </div>
                {(!!storeItems.length && !availableProjectsLoading || searchTerm || netSearch) && (
                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder={isSnip ? 'Search snippets...' : "Search projects..."}
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                        <InputAdornment position="end" className="search-icon">
                          <img src={searchIcon} loading="lazy"/>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
              </div>

              {(availableProjectsLoading || netSearch && !searchTerm) && user?.gitlabapp &&
              <div className="spinner-wrapper">
                <CircularProgress />
              </div>
              }
              {!availableProjectsLoading && (!netSearch || netSearch && !!searchTerm) &&
              <div className="scrollable-div" onScroll={handleScroll}>
                {!!filteredProjects.length && (
                  <div className="form-row head-row">
                    <Checkbox
                      className={'checkbox'}
                      checked={!searchTerm && (storeItems.length === checkedProjects.length || (!!checkedProjects.length && checkedProjects.length === availableItemsLeft))}
                      // disabled={(availableItemsLeft - checkedProjects.length) <= 0}
                      name="repoName"
                      onChange={(e) => {
                        handleSelectAllChange(e.target.checked);
                      }}
                      icon={<CheckboxInputEmpty/>}
                      checkedIcon={<CheckboxInputChecked />}
                    />
                    Name
                  </div>
                )}
                {!!filteredProjects.length && !availableProjectsLoading ?
                  filteredProjects.map(
                    (project, index) =>
                      <div className={(availableItemsLeft - checkedProjects.length) <= 0 && !checkedProjects.includes(project.id) ? 'form-row check-inactive' : 'form-row'} key={`${project.name}-${index}`}>
                        <label key={project.name} className="input-label-checkbox">
                          <Checkbox
                            className={'checkbox'}
                            value={project}
                            checked={checkedProjects.includes(project.id)}
                            name="repoName"
                            onChange={() => {
                              handleChange(project.id);
                            }}
                            icon={<CheckboxInputEmpty/>}
                            checkedIcon={<CheckboxInputChecked />}
                          />
                          {project.name.split('::')[0]}
                        </label>
                      </div>,
                  ) :
                  <div className="form-row with-overflow">
                    {!!searchTerm?.length ?
                      isSnip?translate('notifications.gitlab_repos.no_search_snippets') : translate('notifications.gitlab_repos.no_search')  :
                      subPlan?.planQuantity === subPlan?.quantity ?
                        translate('notifications.trello_boards.nothing_to_add_empty')  :
                        subPlan?.planQuantity === subPlan?.quantity ?
                          translate('notifications.trello_boards.nothing_to_add_empty') :
                          translate('notifications.trello_boards.nothing_to_add')
                    }
                  </div>
                }
                {scroll && (
                  <div className="spinner-wrapper">
                    <CircularProgress />
                  </div>
                )}
              </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>
            {!!storeItems.length && (
              <Button
                onClick={
                  paymentType === EPaymentRequiredType.NON
                    ? handleSubmit(onSubmit)
                    : openTrialModal
                }
                variant="contained" color="primary"
                disabled={!checkedProjects.length || addingProjectsProcessing || ((availableItemsLeft - checkedProjects.length) < 0 && isUserSub)}
              >
                {addingProjectsProcessing &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                </div>
                }
                {addingProjectsProcessing
                  ? translate('notifications.choices.processing')
                  : checkedProjects.length > 1 ?
                    translate(`forms.gitlab_add_edit_project.${isSnip ? 'add_snip_num' : 'add_project_num'}`, {num: checkedProjects.length}) :
                    translate(`forms.gitlab_add_edit_project.${isSnip ? 'add_snip' : 'add_project'}`)}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <PaymentRequiredPoppup
        isOpen={paymentPopup.isOpen}
        closeNotification={closeTrialPopup}
        type={paymentPopup.type}
        platformName={paymentPopup.platformName}
      />
    </>
  );
};

export default memo(GitlabProjectAdd);
