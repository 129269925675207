import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {DataGrid, GridCellParams} from '@mui/x-data-grid';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Archive, HelpCircle} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {EActionTypes} from '../../../constants';
import {EActionStatus, EPaymentRequiredType, EPlatformsNames} from '../../../models/consts';
import {CustomTooltip} from '../../../styles/components/CustomTooltip';
import {DownArrowIcon} from '../../../styles/icons/DownArrowIcon';
import {UpArrowIcon} from '../../../styles/icons/UpArrowIcon';
import {IBackupRow} from '../../../views/PlatformPages/types';
import {Button, Typography} from '../../StyledComponents';
import './style.scss';
import {Chip as MuiChip, Pagination, Stack} from "@mui/material";
import styled from 'styled-components';
import Modal from "../../Dialogs/Modal/Modal";
import RestoreModal from "./RestoreModal/RestoreModal";
import {
  fetchItemsForJiraRestore,
  fetchOrganizationsForTrelloRestore,
  fetchPlacesForGithubRestore
} from "../../../store/actions";
import {useAppDispatch} from "../../../store/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";
import {errorBigIcon} from "../../../assets/icons/icons";
import NotionScope from "../../../views/PlatformPages/NotionPage/NotionActivityView/NotionScope/NotionScope";
import CustomDownloadButton from "../../../styles/components/CustomDownloadButton";
import {IGithubRestorePlace} from "../../../models/models";
import Platform from '../../../models/platforms-data';

interface IBackupsTableProps {
  name: string;
  platformName: string;
  rows: Array<IBackupRow>
  restoreItem: (versions: string, name: string, key: string, emails: string[]) => void
  downloadZip: (item: IBackupRow) => void
  type?: EPaymentRequiredType;
  appWasDeleted?: boolean
  total: number;
  page: number;
  setPage: (number) => void;
  openTutorial?: () => void;
  isLoading: boolean;
  isAdmin: boolean;
  canRestore: boolean;
}

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.success {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }

  &.process {
    background-color: #FEF3C7;
    color: #92400E;

    & span {
      padding-right: 8px;
    }

    &::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="rotate-ico"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>');
      width: 14px;
      height: 14px;
      margin-right: 8px;
      margin-bottom: 1px;
      animation: back-rotation 1.5s infinite linear;
    }
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      fontSize: '14px',
      '& .action-buttons': {
        gap: '16px'
      }
    },
  }),
);

interface IRestoreData {
  id: string;
  backupVer: string;
  date: string;
}

const ActionsTable: FunctionComponent<IBackupsTableProps> = ({
                                                               rows,
                                                               restoreItem,
                                                               downloadZip,
                                                               platformName,
                                                               name,
                                                               type,
                                                               //appWasDeleted
                                                               page,
                                                               setPage,
                                                               isLoading,
                                                               total,
                                                               openTutorial,
                                                               canRestore,
                                                               isAdmin
                                                             }) => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subType = searchParams.get('type');
  const id = String(searchParams.get('id'));
  const status = searchParams.get('status');
  const run = searchParams.get('run');
  const projectTypeKey = searchParams.get('projectTypeKey');
  const classes = useStyles();
  const pageSize = 10;
  const [showModalScope, setShowModalScope] = useState<string>('');
  const [modalScopeData, setModalScopeData] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IRestoreData>({id: '', backupVer: '', date: ''});
  const [jiraRestoreTo, setRestoreTo] = useState<{ id: string; name: string; }[]>([]);
  const [trelloRestoreTo, setTrelloRestoreTo] = useState<{ id: string; name: string; }[]>([]);
  const [githubRestoreTo, setGithubRestoreTo] = useState<IGithubRestorePlace[]>([]);
  const getJiraList = async () => dispatch(fetchItemsForJiraRestore({projectTypeKey})).unwrap()
  const getTrelloOrganizations = async () => dispatch(fetchOrganizationsForTrelloRestore()).unwrap()
  const getGithubPlaces = async () => dispatch(fetchPlacesForGithubRestore()).unwrap()

  useEffect(() => {
    if (platformName === 'jira' && !jiraRestoreTo.length) {
      getJiraList()
        .then(res => {
          setRestoreTo(res.available_projects)
        })
    }
    if (platformName === EPlatformsNames.TRELLO && !trelloRestoreTo.length && status === 'deleted') {
      getTrelloOrganizations()
        .then(res => {
          setTrelloRestoreTo(res.organizations)
        })
    }
    if (platformName === EPlatformsNames.GITHUB && !trelloRestoreTo.length && status === 'deleted') {
      getGithubPlaces()
        .then(res => {
          setGithubRestoreTo(res.places)
        })
    }
  }, [platformName])

  const PaginationFooter = () => {
    const itemFrom = (pageSize * (page + 1)) - pageSize + 1
    const itemTo = (pageSize * (page + 1)) <= total ? (pageSize * (page + 1)) : total
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height='64px'
        px='16px'
      >
        <Typography>{`Showing ${itemFrom} to ${itemTo} of ${total}`}</Typography>
        <Pagination
          count={Math.ceil(total / pageSize)}
          page={page + 1}
          onChange={(e, v) => {
            setPage(v - 1)
          }}
        />
      </Stack>
    )
  };

  useEffect(() => {
    if (!!rows?.length && run) {
      const urlObject = new URL(window.location.href);
      urlObject.searchParams.delete('run')
      const newLink = urlObject.pathname + urlObject.search + urlObject.hash
      navigate(newLink, { replace: true })
      const firstItem = rows.find(i => (i.status === 'success' && i.type === 'backup'))
      if (firstItem) {
        setShowModal(true)
        setModalData({
          id: firstItem?.key,
          date: new Date(+firstItem?.date).toLocaleString(),
          backupVer: firstItem?.versions || firstItem?.versionid
        })
      }
    }
  }, [rows]);

  return (
    <>
      <DataGrid
        loading={isLoading}
        rowCount={total}
        paginationMode="server"
        className={classes.root}
        rows={rows}
        columns={[
          {
            field: 'date',
            headerName: translate('views.platform_pages.data_grid.dateTime'),
            // width: !isMobile ? 180 : 120,
            minWidth: 150,
            flex: 1,
            editable: false,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            headerClassName: 'backup-table--header',
            valueFormatter: ({value}) => new Date(value as string).toLocaleString(),
          },
          {
            field: 'type',
            headerName: translate('views.platform_pages.data_grid.type'),
            flex: 1,
            // width: !isMobile ? 180 : 120,
            editable: false,
            sortable: true,
            headerClassName: 'backup-table--header',
            cellClassName: 'backup-table--type-cell',
            valueFormatter: ({value}) => 'start_backup_unit' === value ? 'backup' : 'start_restore_unit' === value ? 'restore' : value,
          },
          {
            field: 'size',
            headerName: translate('views.platform_pages.data_grid.sizeBackup'),
            flex: 1,
            // width: !isMobile ? 150 : 120,
            editable: false,
            sortable: true,
            headerClassName: 'backup-table--header',
          },
          {
            field: 'sizeSub',
            headerName: 'Incremental size',
            flex: 1,
            // width: !isMobile ? 150 : 120,
            editable: false,
            sortable: true,
            headerClassName: 'backup-table--header',
            hide: (platformName === 'gitlab' || platformName === 'github')
          },
          {
            field: 'status',
            headerName: translate('views.platform_pages.data_grid.status'),
            flex: 1,
            minWidth: 440,
            editable: false,
            sortable: true,
            headerClassName: 'backup-table--header',
            renderCell: (params: GridCellParams) => {
              // const downloadArchive = params?.row?.expire_date_zip_url >= Date.now() &&
              //   params?.row?.download_zip_url &&
              //   params?.row?.zip_status === 'created'
              //${JSON.parse(JSON.parse(rows[+params.id].error_message || '["[]"]'))?.errorMessage || ''}
              return (
                <div className="status-group">
                  {params.value === EActionStatus.SUCCESS
                    ?
                    <Chip
                      className={!!params.row.action_process?.length ? 'process' : 'success'}
                      label={!!params.row.action_process?.length ?
                        translate(`notifications.all.process.${params.row.action_process}`) : params.value}
                    />
                    : (
                          <div>
                            <Chip
                              className={'error'}
                              label={params.value}
                            />
                            <CustomTooltip title={`${params.row.type === "restore" ? `. Unable to restore. Check your ${Platform[platformName].title} account to investigate any limitations.` : ' '}`}>
                              <HelpCircle className="info-icon" />
                            </CustomTooltip>
                          </div>
                    )}
                  {
                    !params.row.action_process?.length && params.value === EActionStatus.SUCCESS &&
                    params.row.type === EActionTypes.BACKUP &&
                      <div className="action-buttons">
                          <CustomDownloadButton
                              endDateInMilliseconds={params?.row?.expire_date_zip_url}
                              downloadUrl={params?.row?.download_zip_url}
                              download={() => downloadZip(rows[+params.id])}
                              disabled={!isAdmin}
                          />
                        {platformName !== 'notion' && (
                          <CustomTooltip title={!canRestore ? 'Please wait until another element has completed its action.' : ''}>
                            <Button className="restore-btn" variant="contained" color="secondary"
                                    disabled={type !== EPaymentRequiredType.NON || !canRestore || !isAdmin}
                                    onClick={() => {
                                      if (platformName === 'jira') {
                                        Swal.fire({
                                          title: 'Jira: Restore Confirmation',
                                          html: '<div><p><div>1. Create a new project and copy the settings of the original</div><div>2. When you restore, choose a designated project to restore to</div></p> <p><b>Warning!</b> If you do not, it will duplicate your items on the same project</p></div>',
                                          showCancelButton: true,
                                          showDenyButton: true,
                                          denyButtonText: 'Tutorial',
                                          cancelButtonText: translate('notifications.choices.cancel'),
                                          confirmButtonText: translate('notifications.choices.continue'),
                                          imageUrl: errorBigIcon,
                                          showLoaderOnConfirm: true,
                                        })
                                          .then(res => {
                                            if (res.isConfirmed) {
                                              setShowModal(true)
                                              setModalData({
                                                id: params.row.key,
                                                date: new Date(+params.row.date).toLocaleString(),
                                                backupVer: params.row.versions || params.row.versionid
                                              })
                                            } else if (res.isDenied) {
                                              openTutorial && openTutorial()
                                            }
                                          })
                                      } else {
                                        setShowModal(true)
                                        setModalData({
                                          id: params.row.key,
                                          date: new Date(+params.row.date).toLocaleString(),
                                          backupVer: params.row.versions || params.row.versionid
                                        })
                                      }
                                    }}
                            >
                              {translate('views.platform_pages.data_grid.restoreDot')}
                            </Button>
                          </CustomTooltip>
                        )}
                        {platformName === 'notion' && (
                          <Button variant="outlined" color="primary"
                                  onClick={() => {
                                    setShowModalScope(params.row.versions)
                                    setModalScopeData(new Date(+params.row.date).toLocaleString())
                                  }}>
                            <Archive className="feather-icon-in-button"/>
                            {translate('views.platform_pages.data_grid.viewScope')}
                          </Button>
                        )}
                      </div>
                  }
                </div>
              )
            },
          },
        ]}
        headerHeight={48}
        rowHeight={64}
        pageSize={pageSize}
        page={page}
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        initialState={{
          sorting: {
            sortModel: [{field: 'date', sort: 'desc'}],
          },
        }}
        components={{
          ColumnSortedAscendingIcon: DownArrowIcon,
          ColumnSortedDescendingIcon: UpArrowIcon,
          Footer: PaginationFooter,
          ColumnUnsortedIcon: null,
        }}
      />
      <NotionScope
        workspaceId={id}
        isOpen={!!showModalScope.length}
        closeNotification={() => setShowModalScope('')}
        workspaceScopeId={showModalScope}
        modalScopeData={modalScopeData}
      />

      <Modal
        isOpen={showModal}
        className='restore-wrap'
        closeNotification={() => setShowModal(false)}
        title={(platformName === 'trello') ?
          'Restore boards' :
          platformName === 'jira' ?
            'Restore issues' :
          platformName === 'github' ?
            (!!subType ? 'Restore gist' : 'Restore repository') :
            platformName === 'gitlab' && !!subType ?
              'Restore snippet' :
              'Restore project'
        }
        child={
          <RestoreModal
            close={() => setShowModal(false)}
            platformName={platformName}
            name={name} id={modalData.id}
            isGist={!!subType}
            backupVer={modalData.backupVer}
            date={modalData.date}
            restore={restoreItem}
            jiraRestore={jiraRestoreTo}
            projectTypeKey={projectTypeKey}
            trelloRestoreTo={trelloRestoreTo}
            githubRestoreTo={githubRestoreTo}
            canRestore={canRestore}
          />
        }
      />
    </>
  );
};

export default (ActionsTable);
