import {Chip as MuiChip, CircularProgress, Grid} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '../../components/StyledComponents';
import {IUser} from '../../models/models';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getUserSelector,
  githubActualPlan,
  githubTrialHoursLeft,
  gitlabActualPlan,
  gitlabTrialHoursLeft, isSubUser,
  jiraActualPlan,
  jiraTrialHoursLeft,
  loadingUser,
  notionActualPlan, roleByApp,
  trelloActualPlan,
  trelloTrialHoursLeft
} from '../../store/selectors';
import withRedirectAfterLogout from '../../utils/withRedirectAfterLogout';
import Stats from './Stats';
import './style.scss';
import Table from './Table';
import {getStatisticsData} from "../../store/actions";
import {IPlatformSubscriptionRow} from "../UserPages/Subscription/Subscription";
import Platform from "../../models/platforms-data";
import {
  EPlatformsNames,
  errorSubscriptionStatuses,
  EStripeSubStatus,
  inactiveSubscriptionStatuses,
  unpaidSubscriptionStatuses
} from "../../models/consts";
import {useNavigate} from "react-router-dom";
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import styled from 'styled-components';
import {Plus} from "react-feather";
import addApp from '../../assets/icons/add-app.svg'
import {BadgesNotionUpd, BadgesSubscription, BadgesTrial} from "../../components/Badges/Badges";
import {IError} from "../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../utils/popUpTextDecorator";
import {errorBigIcon} from "../../assets/icons/icons";
import {iniFrame} from "../../serviceWorker";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {EWebsocketMethods} from "../../constants";

type ActivityRowTypes = {
  id: number,
  name: string,
  action: string,
  date: string,
  status: string,
  platform: string,
};

const Chip = styled(MuiChip)`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.unpaid {
    background-color: #FEF3C7;
    color: #92400E;
  }

  &.active {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }

  &.inactive {
    background-color: #F3F4F6;
    color: #1F2937;
  }

`;

const DashboardPage: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: IUser | null = useAppSelector(getUserSelector);
  const userLoading = useAppSelector(loadingUser);
  const isUserSub = useAppSelector(isSubUser);
  const roles = useAppSelector(roleByApp);

  const githubSubItem = useAppSelector(githubActualPlan);
  const trelloSubItem = useAppSelector(trelloActualPlan);
  const gitlabSubItem = useAppSelector(gitlabActualPlan);
  const jiraSubItem = useAppSelector(jiraActualPlan);
  const notionSubItem = useAppSelector(notionActualPlan);

  const trialTrello = useAppSelector(trelloTrialHoursLeft);
  const trialGithub = useAppSelector(githubTrialHoursLeft);
  const trialGitlab = useAppSelector(gitlabTrialHoursLeft);
  const trialJira = useAppSelector(jiraTrialHoursLeft);
  const trialNotion = useAppSelector(jiraTrialHoursLeft);

  const [itemsAmount, setItemsAmount] = useState<Record<string, number>>();
  const [rows, setRows] = useState<Array<IPlatformSubscriptionRow>>();
  const [activityRows, setActivityRows] = useState<Array<ActivityRowTypes>>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const {onMessage} = useWebSocket();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [countOfAddedItems, setCountOfAddedItems] = useState<any>({});
  const trials: Array<string> = ['trello', 'github', 'gitlab', 'jira'];
  const activeTrials = trials.filter(item => {
    return (item === 'trello' && trialTrello && trelloSubItem.status === 'active') ||
      (item === 'github' && trialGithub && githubSubItem.status === 'active') ||
      (item === 'jira' && trialJira && jiraSubItem.status === 'active') ||
      (item === 'notion' && trialNotion && notionSubItem.status === 'active') ||
      (item === 'gitlab' && trialGitlab && gitlabSubItem.status === 'active')
  })
  const activePaid = trials.filter(item => {
    return (item === 'trello' && !trialTrello && trelloSubItem.status === 'active') ||
      (item === 'github' && !trialGithub && githubSubItem.status === 'active') ||
      (item === 'jira' && !trialJira && jiraSubItem.status === 'active') ||
      (item === 'notion' && !trialNotion && notionSubItem.status === 'active') ||
      (item === 'gitlab' && !trialGitlab && gitlabSubItem.status === 'active')
  })
  // const [size, setSize] = useState<Record<string, number>>();//TODO: use in future for add size platforms statistics

  useEffect(() => {
    const getDashData = async () => {
      setLoadingData(true);
      try {
        const resp = await dispatch(getStatisticsData());
        // console.log(resp)
        const dashData = resp?.payload
        const r: ActivityRowTypes[] = []
        let id = 0;
        // const sizes = {};
        if (dashData) {
          if (dashData.platforms_items_count) {
            setItemsAmount(dashData.platforms_items_count)
          } else {
            setItemsAmount({})
          }
          const platformsAddedItems = {}
          Object.keys(Platform).forEach(item => {
            // if (dashData[item]) {
            //   sizes[item] = dashData[item].storage_size || 0
            // }
            platformsAddedItems[item] = dashData[item]?.items?.length || 0
            if (dashData[item] && dashData[item].items && !!dashData[item]?.items?.length) {
              dashData[item].items.forEach(el => {
                if (!!el.last_activity_actions?.length) {
                  el.last_activity_actions.forEach(act => {
                    id += 1
                    r.push({
                      id,
                      name: el.name,
                      action: act.type,
                      date: act.date,
                      status: act.status,
                      platform: Platform[item].title
                    });
                  })
                }
              })
            }
          })
          // setCountOfAddedItems(platformsAddedItems)
          setActivityRows(r.sort((a, b) => Number(b.date) - Number(a.date)).slice(0, 10))
          // setSize({...sizes})
        }
      } catch (err) {
        const error = err as IError;
        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.message || error.error as string),
          imageUrl: errorBigIcon,
        });
        console.log(err);
      } finally {
        setLoadingData(false)
        // setTimeout(()=>setLoadingData(false), 1000)
      }
    }
    getDashData()
    onMessage((data) => {
      if (data.method === EWebsocketMethods.updateDashboardPlatformItemsAmount) {
        setItemsAmount(data.platforms_items_count)
      }
    });

  }, [])

  useEffect(() => {
    if (userLoading) {
      return;
    }
    setRows([githubSubItem, trelloSubItem, gitlabSubItem, jiraSubItem, notionSubItem]);
  }, [githubSubItem, trelloSubItem, gitlabSubItem, jiraSubItem, notionSubItem]); // userLoading

  useEffect(() => {
    if (user && !user.hadEverAnySubscriptions && !userLoading) {
      if (iniFrame()) {
        navigate('app-connector?jiraInIframe=true')
      } else {
        navigate('app-connector')
      }
    }
  }, [user, userLoading])

  const columns = [
    {
      field: 'platformName',
      headerName: translate('subscriptions.subscriptions_list.app'),
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      renderCell: (params: GridCellParams) => (
        <div className='subscriptions-logo-dash'>
          <img src={Platform[params.value as EPlatformsNames]?.smallLogo} className="platformLogo" loading="lazy"/>
          {Platform[params.value as EPlatformsNames]?.title}
        </div>
      ),
    },
    {
      field: 'plan',
      headerName: translate('subscriptions.subscriptions_list.subscription'),
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      renderCell: (params: GridCellParams) => {
        return params.value ? params.value === 'Trial' ? 'Free Trial' : params.value : 'N/A';
      }
    },
    {
      field: 'status',
      headerName: translate('subscriptions.subscriptions_list.status'),
      minWidth: 140,
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      cellClassName: 'subscription-table--cell',
      renderCell: (params: GridCellParams) => {
        let subscriptionStatusClass = 'active';
        let subscriptionStatus = translate('subscriptions.statuses.active');

        if (params.row.isConnect && (unpaidSubscriptionStatuses.includes(params.value as EStripeSubStatus) || params.row.plan === 'Trial')) {
          subscriptionStatusClass = 'unpaid';
          subscriptionStatus = params.row.plan === 'Trial' ? 'Trial period' : params.value.replaceAll('_', ' ');
        } else if (params.row.isConnect && inactiveSubscriptionStatuses.includes(params.value as EStripeSubStatus)) {
          subscriptionStatusClass = 'inactive';
          subscriptionStatus = params.value.replaceAll('_', ' ');
        } else if (!params.row.isConnect || errorSubscriptionStatuses.includes(params.value as EStripeSubStatus)) {
          subscriptionStatusClass = 'error';
          subscriptionStatus = params.row.isConnect ? params.value.replaceAll('_', ' ') : 'Not connected';
        }

        return (
          <div className='status'>
            <Chip label={subscriptionStatus} className={subscriptionStatusClass}/>
            <br/>
            {params.row.isConnect && params.row.finishDate && params.row.status === 'active' && (
              <span className='active-until'>Until: {params.row.finishDate}</span>
            )}
          </div>
        );
      },
    }
  ];

  return (
    <div id="dashboard-page">
      {!activityRows || loadingData ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="header-block">
          <Typography variant="h3" display="block" mb='32px'>
            {translate('common.default_pages.dashboard')}

            {!isUserSub && (
              <Button mr={2} variant="contained" color="primary"
                      onClick={() => navigate('app-connector')}
              >
                <Plus className='feather-icon-in-button'/>
                {translate('common.buttons.addPlatform')}
              </Button>
            )}
          </Typography>
          {!isUserSub && notionSubItem?.addedItemsQuantity === 1 && notionSubItem?.status === 'active' && (
            <BadgesNotionUpd platformName='notion'/>
          )}
          {(trialGithub || trialTrello || trialGitlab || trialJira) && githubSubItem && trelloSubItem && gitlabSubItem && jiraSubItem &&
            activeTrials.map(item => {
              const days = Number(item === 'trello' ? trialTrello : item === 'gitlab' ? trialGitlab : item === 'jira' ? trialJira : item === 'notion' ? trialNotion : trialGithub) || 0
              return (
                <BadgesTrial key={item} trialDays={days} platformName={item} isDash={true}/>
              )
            })
          }
          {githubSubItem && trelloSubItem && gitlabSubItem && jiraSubItem &&
            activePaid.map(item => {
              const subInfo = item === 'trello' ? trelloSubItem : item === 'gitlab' ? gitlabSubItem : item === 'jira' ? jiraSubItem : item === 'notion' ? notionSubItem : githubSubItem
              const show = subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo?.finishDateInMillisecond - Date.now()) / (24 * 60 * 60 * 1000)) < 1
              if (show) {
                return (
                  <BadgesSubscription key={item} platformName={item} isDash={true}/>
                )
              }
            })
          }
          <Grid container spacing={6}>
            {!!rows?.length && user &&
              rows.map(item => {
                if (
                  ((isUserSub && roles && Object.keys(roles).find(i => i === item.platformName)) || !isUserSub) &&
                  ((item.status !== 'not started' && item.status !== 'inactive') || (item.plan === '-'))
                ) {
                  return (
                    <Grid key={item.platformName} item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Stats
                        platform={item.platformName}
                        itemsAmount={itemsAmount[item.platformName]}
                        title={Platform[item.platformName as EPlatformsNames]?.title || ''}
                        isTrialEnd={item.status !== 'active' && (item.plan === '-' || item.plan === 'Essential' || item.plan === '')}
                        plan={item.plan}
                        isConnect={item.isConnect}
                      />
                    </Grid>
                  )
                }
              })}
            {!isUserSub && (
              <Grid key='empty0' item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='dashboard-page-add-app' onClick={() => navigate('app-connector')}>
                  <img src={addApp} loading="lazy"/>
                  <div className='dashboard-page-add-app-text'>
                    {translate('views.dashboard.addApp')}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12} lg={12}>
              <Typography variant="h4" display="block" className='block-head'>
                {translate('common.default_pages.activity')}
              </Typography>
              <Table data={activityRows}/>
            </Grid>
            <Grid item xs={12} lg={8}>
              {!isUserSub && rows && !!rows.length && user && user.hadEverAnySubscriptions && (
                <>
                  <Typography variant="h4" display="block" className='block-head'>
                    Your subscriptions
                  </Typography>
                  <div className="subscriptions-block">
                    <DataGrid
                      disableColumnMenu
                      disableColumnFilter
                      rows={rows}
                      disableSelectionOnClick
                      rowHeight={87}
                      hideFooter
                      autoHeight
                      columns={columns}
                      disableColumnSelector
                    />
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(DashboardPage);
